<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="clients-id" v-else>
      <UiBanner fluid :title="currentClient.name" :breadcrumbs="breadcrumbs" />

      <v-tabs v-model="currentTab">
        <v-tab class="tw-normal-case" v-for="(tab, idx) in tabs" :key="idx" :href="`#${tab.key}`">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <!-- General Section -->
      <client-general
        ref="clientGeneral"
        v-if="currentTab === 'general'"
        :saving="saving"
        :current-client="currentClient"
        :features-list="featuresList"
        :locales="locales"
        @updateClient="saveClientGeneral"
      />

      <!-- Algolia Section -->
      <client-algolia
        v-if="currentTab === 'algolia'"
        :saving="saving"
        :current-client="currentClient"
        @updateClient="saveClient"
      />

      <!-- OhGroups Section -->
      <client-oh-groups
        v-if="currentTab === 'ohGroups'"
        :saving="saving"
        :current-client="currentClient"
        @updateClient="saveClient"
      />

      <!-- Synchro Section -->
      <client-synchro
        v-if="currentTab === 'synchro'"
        :saving="saving"
        :current-client="currentClient"
        :availables-front-offices="availablesFrontOffices"
        @updateSyncSettings="saveSyncSettings"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiMagnify, mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import ManageMediaMixin from '@/mixins/manageMedia.mixin'
import UiBanner from '@/components/UI/Banner.vue'
import ClientGeneral from '@/views/Clients/General.vue'
import ClientAlgolia from '@/views/Clients/Algolia.vue'
import ClientOhGroups from '@/views/Clients/OhGroups.vue'
import ClientSynchro from '@/views/Clients/Synchro.vue'

export default {
  name: 'ClientsId',
  components: {
    UiBanner,
    ClientGeneral,
    ClientAlgolia,
    ClientOhGroups,
    ClientSynchro,
  },
  mixins: [ManageMediaMixin],
  data: () => ({
    icons: {
      mdiMagnify,
      mdiCheckCircle,
      mdiCloseCircle,
    },
    saving: false,
    search: '',
  }),
  async created() {
    this.setUpdating(true)
    try {
      await Promise.all([
        this.getClient({ clientId: this.$route.params.id }),
        this.getFeaturesList({ clientId: this.$route.params.id }),
        this.getClientFrontOffices({ clientId: this.$route.params.id }),
      ])
    } catch (e) {
      // console.log(e)
    }
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,
      featuresList: state => state.client.featuresList,
      availablesFrontOffices: state => state.client.availablesFrontOffices,
      currentFrontOffice: state => state.client.currentFrontOffice,

      locales: state => state.backoffice.locales,
      updating: state => state.backoffice.updating,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('clients.list.name'),
          to: {
            name: 'ClientsList',
          },
          exact: true,
        },
        {
          text: this.currentClient.name,
          to: {
            name: 'Client',
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('clients.id.general.name'),
          key: 'general',
        },
        {
          label: this.$t('clients.id.algolia.name'),
          key: 'algolia',
        },
        {
          label: this.$t('clients.id.ohGroups.name'),
          key: 'ohGroups',
        },
        ...(this.currentClient.withGMB === true
          ? [
              {
                label: this.$t('clients.id.synchro.name'),
                key: 'synchro',
              },
            ]
          : []),
      ]
    },

    currentTab: {
      get() {
        return this.$route.params.currentTab
      },
      set(tab) {
        return this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
      },
    },
  },
  methods: {
    ...mapActions({
      getClient: 'client/getClient',
      getClientFrontOffices: 'client/getClientFrontOffices',
      updateClient: 'client/updateClient',
      getFeaturesList: 'client/getFeaturesList',
      manageFeature: 'client/manageFeature',
      setCurrentFrontOffice: 'client/setCurrentFrontOffice',

      setUpdating: 'backoffice/setUpdating',
      uploadMedia: 'tool/uploadMedia',
      removeMedia: 'tool/removeMedia',
      setAlert: 'backoffice/setAlert',

      initBaseIndex: 'algolia/initBaseIndex',
    }),
    async saveClient(payload) {
      this.saving = true
      try {
        await this.updateClient({ client: payload.client })
        this.showNotificationSuccess()
      } catch {
        this.showNotificationError()
      }
    },
    async saveSyncSettings(payload) {
      this.saving = true
      try {
        const client = { ...this.currentClient, customRules: payload.customRules }
        await this.updateClient({ client })
        this.showNotificationSuccess()
      } catch {
        this.showNotificationError()
      }
    },
    async saveClientGeneral(payload) {
      this.saving = true
      const newMedia = await this.manageMedia({
        target: 'logo',
        media: this.currentClient.logo,
        payloadNewMedia: payload.clientLogo,
        payloadMedia: payload.client.logo,
        mediaName: this.currentClient.name,
        mediaId: this.currentClient.id,
      })
      if (newMedia) {
        payload.client.logo = newMedia
      }

      await Promise.all(
        payload.featuresList.map(async feature => {
          await this.manageFeature({ feature, clientId: this.currentClient.id })
        })
      )
      try {
        await this.updateClient({ client: payload.client })
        await this.getFeaturesList({ clientId: this.currentClient.id })
        this.showNotificationSuccess()
        this.$refs.clientGeneral.resetCurrentFile('clientLogo')
      } catch {
        this.showNotificationError()
      }
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
    showNotificationSuccess() {
      this.setAlert({
        color: 'success',
        text: this.$t('notification.success.client', { client: this.currentClient.name }),
      })
      this.saving = false
    },
  },
}
</script>
